<template>
    <div class="action-notice-inform-document">
        <div class="title f-bold" v-html="message.mtype === 'waive-confirmed' ? $translate('ACTION_NOTICE_WAIVE_CONFIRMED') : $translate('ACTION_NOTICE_WAIVE_REJECTED')" />
        <div class="body">
            <div class="waive-status" v-if="message.mtype === 'waive-confirmed'">
            </div>
            <div class="waive-status" v-else>
                반려 사유: {{ content.waive_reject_reason }} <br />
                약속 상대: {{ content.target_name }} <br />
                약속 ID: {{ content.schedule_id }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ActionWaiveConfirmed',
    props: ['message'],
    computed: {
        getMeetDate() {
            if (!this.content.date_confirmed) {
                return ''
            }
            return (
                this.$moment(this.content.date_confirmed).format('YY/MM/DD') +
                ' ' +
                ['일', '월', '화', '수', '목', '금', '토'][this.$moment(this.date).day()]
            )
        },
        getMeetTime() {
            if (!this.content.date_confirmed) {
                return ''
            }
            return (
                (this.$moment(this.content.date_confirmed).format('A') === 'AM' ? '오전' : '오후') +
                ' ' +
                this.$moment(this.content.date_confirmed).format('h')
            )
        },
        content() {
            return this.$mustParse(this.message.content)
        }
    },
}
</script>
